import StimulusController from "../lib/stimulus_controller";
import {FetchRequest} from "../lib/request";

export default class extends StimulusController<HTMLFormElement> {
    selectedValue =  '';

    static targets = [ 'option', 'field' ]
    declare readonly hasOptionTarget: boolean
    declare readonly optionTarget: HTMLInputElement
    declare readonly optionTargets: HTMLInputElement[]
    declare readonly hasFieldTarget: boolean
    declare readonly fieldTarget: HTMLInputElement
    declare readonly fieldTargets: HTMLInputElement[]

    connect() {
        this.update();
    }

    update() {
        this.selectedValue = this.optionTarget.value;
        this.fieldTargets.forEach( (field) => {
            if(field.dataset?.displayForOptions && field.dataset.displayForOptions.includes(this.selectedValue)) {
                field.closest('.field')?.classList.remove('!hidden');
                if(field.name == "releve_billetterie_filter[date_min]") {
                    // date_min and date_max are in the same flex container, we hide it to avoid extra gap from form
                    // that is a flex column gapped, these 2 fields have the same displayForOptions
                    field.closest('.field')?.closest(".flex")?.classList.remove("!hidden");
                }
                let defaultString = 'defaultFor'+this.toPascalCase(this.selectedValue);
                let newValue = field.dataset[defaultString];
                if(newValue !== undefined) {
                    field.setAttribute('value', newValue);
                }
                if (field.dataset?.disableFor && field.dataset.disableFor.includes(this.selectedValue)) {
                    field.disabled = true;
                } else {
                    field.disabled = false;
                }
            } else {
                field.disabled = true;
                field.closest('.field')?.classList.add('!hidden');
                if(field.name == "releve_billetterie_filter[date_min]") {
                    // date_min and date_max are in the same flex container, we hide it to avoid extra gap from form
                    // that is a flex column gapped, these 2 fields have the same displayForOptions
                    field.closest('.field')?.closest(".flex")?.classList.add("!hidden");
                }
            }
        })
    }

    toPascalCase(string :string) {
        return string.split('_').map((subString)=>{
            return subString.charAt(0).toUpperCase()+subString.slice(1)
        }).join('');
    }
}