declare global {
  interface Element {
    getHeight(): number,

    slideUp(delay?: number): Promise<{ collapsed: boolean }>,

    slideDown(delay?: number): Promise<{ collapsed: boolean }>,

    slideToggle(delay?: number): Promise<{ collapsed: boolean }>,

    offsetFrom(el: Element): {
      top: number,
      left: number,
      right: number,
      bottom: number,
      width: number,
      height: number,
      child: {
        top: number,
        left: number,
        right: number,
        bottom: number,
        width: number,
        height: number,
      }
      parent: {
        top: number,
        left: number,
        right: number,
        bottom: number,
        width: number,
        height: number,
      }
    } | undefined;

    isDescendantOf(el: Element): boolean;
  }
}


Element.prototype.getHeight = function () {
  if (!(this instanceof HTMLElement)) return 0;
  let el = this,
    el_style = window.getComputedStyle(el),
    el_display = el_style.display,
    el_max_height = el_style.maxHeight.replace('px', '').replace('%', ''),

    wanted_height = 0;


  // if its not hidden we just return normal height
  if (el_display !== 'none' && el_max_height !== '0') {
    return el.offsetHeight;
  }


  // the element is hidden so:
  // making the el block so we can meassure its height but still be hidden
  el.style.position = 'absolute';
  el.style.width = '100%';
  el.style.visibility = 'hidden';
  el.style.pointerEvents = 'none';
  el.style.display = 'block';
  el.style.maxHeight = '';
  if (getComputedStyle(el).maxHeight) el.style.maxHeight = '99999rem';

  wanted_height = el.offsetHeight;

  // reverting to the original values
  el.style.display = "";
  el.style.position = "";
  el.style.width = "";
  el.style.visibility = "";
  el.style.pointerEvents = "";
  el.style.maxHeight = el_max_height;

  return wanted_height;
}

Element.prototype.slideDown = function (delay = 500) {
  if (!(this instanceof HTMLElement)) return Promise.resolve({collapsed: true});
  let el = this,
    el_max_height = "0",
    el_style = getComputedStyle(el);

  if (el_style.opacity !== "0") return Promise.resolve({collapsed: true});

  console.log(el.getHeight())
  el_max_height = el.getHeight() + 'px';
  el.style.marginTop = "-" + el_style.borderTopWidth;
  el.style.marginBottom = "-" + el_style.borderBottomWidth;
  el.style.overflowY = 'hidden';
  el.style.maxHeight = '0';
  el.style.transition = `max-height ${delay}ms ease-out, margin ${delay}ms ease-out, padding ${delay}ms ease-out, opacity ${delay}ms ease-out`;
  el.style.opacity = "0";
  el.style.pointerEvents = "";
  el.style.position = "";
  el.style.width = "";

  // we use setTimeout to modify maxHeight later than display (to we have the transition effect)
  setTimeout(function () {
    el.style.maxHeight = `${el_max_height}`;
    el.style.marginTop = "";
    el.style.marginBottom = "";
    el.style.opacity = "1";
  }, 1);
  return new Promise((resolve) => {
    setTimeout(function () {
      el.style.transition = "";
      el.style.overflowY = "";
      resolve({collapsed: false});
    }, delay);
  });

}

Element.prototype.slideUp = function (delay = 500) {
  if (!(this instanceof HTMLElement)) return Promise.resolve({collapsed: false});
  let el = this,
    el_max_height = "0",
    el_style = getComputedStyle(el);

  if (el.style.opacity === "0") return Promise.resolve({collapsed: false});

  el_max_height = el.getHeight() + 'px';
  el.style.overflowY = 'hidden';
  el.style.maxHeight = el_max_height;
  el.style.transition = `max-height ${delay}ms ease-out, margin ${delay}ms ease-out, padding ${delay}ms ease-out, opacity ${delay}ms ease-out`;
  el.style.opacity = el_style.opacity;

  // we use setTimeout to modify maxHeight later than display (to we have the transition effect)
  setTimeout(function () {
    el.style.maxHeight = "0";
    el.style.marginTop = "-" + el_style.borderTopWidth;
    el.style.marginBottom = "-" + el_style.borderBottomWidth;
    el.style.opacity = "0";
  }, 1);

  return new Promise((resolve) => {
    setTimeout(function () {
      el.style.marginTop = "";
      el.style.marginBottom = "";
      el.style.transition = "";
      el.style.display = "";
      el.style.opacity = "0";
      el.style.pointerEvents = "none";
      el.style.position = "absolute";
      el.style.width = "100%";
      resolve({collapsed: true});
    }, delay);
  });
}

Element.prototype.slideToggle = function (delay = 500) {
  if (!(this instanceof HTMLElement)) return Promise.resolve({collapsed: false});
  let el = this;
  if (getComputedStyle(el).opacity === "0") {
    return el.slideDown(delay);
  } else {
    return el.slideUp(delay);
  }
}

document.addEventListener("input", ({target}) => {
  if (!(target instanceof HTMLInputElement)) return;
  if (target.tagName === "INPUT" && target.value) target.classList.add("filled")
  else target.classList.remove("filled")
});

Element.prototype.offsetFrom = function (parent) {
  if (!(this instanceof HTMLElement) && !(this instanceof SVGElement)) return;
  if (!(parent instanceof HTMLElement) && !(this instanceof SVGElement)) return;
  const parentPos = parent.getBoundingClientRect();
  const childPos = this.getBoundingClientRect();
  
  return {
    top: childPos.top - parentPos.top,
    right: childPos.right - parentPos.right,
    bottom: childPos.bottom - parentPos.bottom,
    left: childPos.left - parentPos.left,
    width: (childPos.right - childPos.left) - (parentPos.right - parentPos.left),
    height: (childPos.bottom - childPos.top) - (parentPos.bottom - parentPos.top),
    parent: {
      top: parentPos.top,
      right: parentPos.right,
      bottom: parentPos.bottom,
      left: parentPos.left,
      width: parentPos.right - parentPos.left,
      height: parentPos.bottom - parentPos.top,
    },
    child: {
      top: childPos.top,
      right: childPos.right,
      bottom: childPos.bottom,
      left: childPos.left,
      width: childPos.right - childPos.left,
      height: childPos.bottom - childPos.top,
    }
  };
}

Element.prototype.isDescendantOf = function (parent) {
  let node = this.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export {}