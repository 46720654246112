import * as Turbo from '@hotwired/turbo'
import {Application} from "@hotwired/stimulus"
import {registerControllers} from 'stimulus-vite-helpers'
import ReactRailsUJS from "react_ujs";
import {viteConstructorRequireContext} from "./ujs_vite_require_constructor";
import "preact/debug"
import {I18n as I18nConst} from "i18n-js";
import translations from "./locales.json";
// webcomponents polyfill
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'

ReactRailsUJS.getConstructor = viteConstructorRequireContext(import.meta.glob('./components/**/*.{js,ts,jsx,tsx}', {eager: true}))

declare global {
  interface Window {
    I18n: I18nConst
  }
}

window.I18n = new I18nConst();
window.I18n.store(translations);
window.I18n.enableFallback = true;

//declare global variables
declare global {
  export interface Window {
    Stimulus: Application,
    debug: boolean,
    Turbo: typeof Turbo
  }
}

//define debug state variable
window.debug = ['development', 'test'].includes(import.meta.env.RAILS_ENV)
if (window.debug) console.log(`Vite ⚡️ Rails Start in debug mode`)

//start turbo
// @ts-ignore Poor turbo typing since creator dropped typescript support at v8.0.0
Turbo.start()

//load all addons
const addons: Record<string, any> = import.meta.glob('./addons/**/*_addon.{js,ts,tsx}', {eager: true})
const addonNames = Object.keys(addons).map(path => path.replace('./addons/', '').replace(/_addon\.(js|ts)/, ''))
for (const path in addons) {
  const addonName = path.replace('./addons/', '').replace(/_addon\.(js|ts)/, '')
  if (addons[path] && "dependencies" in addons[path]) {
    if (!addons[path].dependencies.every((dep: string) => addonNames.includes(dep))) {
      console.error(`Addon "${addonName}" has missing dependencies:`, addons[path].dependencies)
      continue
    }
  }
  if (addons[path] && "init" in addons[path]) addons[path].init.call(null)
  console.log(`Loaded addon "${addonName}":`, addons[path])
}

//load all custom elements
const customEls: Record<string, any> = import.meta.glob('./custom_els/**/*.{js,ts,tsx}', {eager: true})
for (const path in customEls) {
  const customElementName = path.replace('./custom_els/', '').replace(/\.(js|ts|tsx)/, '').replace(/_/g, '-')
  if (customEls?.[path].default) {
    customElements.define(customElementName, customEls[path].default)
    if (window.debug) console.log(`Loaded custom element "${customElementName}":`, customEls[path].default)
  }
}

//Start stimulus application (Must be this scheme to enable hot module reload)
const application = Application.start()
window.Stimulus = application
application.debug = window.debug
const controllers = import.meta.glob('./controllers/**/*_controller.{js,ts,tsx}', {eager: true});
registerControllers(application, controllers)