export const viteConstructorRequireContext = function(reqCtx: Record<string, any>) {
  const fromRequireContext = function(reqCtx: Record<string, any>) {
    return function(className: string) {
      const parts = className.split(".");
      const filename = parts.shift();
      if (!filename) throw new Error("Invalid class name: " + className);
      const keys = parts;
      // Load the module:
      const componentPath = Object.keys(reqCtx).find((path => path.search(filename) > 0));
      if (!componentPath) throw new Error("Invalid class name: " + className);
      let component = reqCtx[componentPath];
      // Then access each key:
      keys.forEach(function(k) {
        component = component[k];
      });
      component = component.default;
      return component;
    }
  }

  const fromCtx = fromRequireContext(reqCtx);
  return function(className: string) {
    let component;
    try {
      // `require` will raise an error if this className isn't found:
      component = fromCtx(className);
    } catch (firstErr) {
      console.error(firstErr);
    }
    return component;
  }
}