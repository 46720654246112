declare global {
  interface NodeListOf<TNode extends Node> extends NodeList {
    find(predicate: (value: TNode, index: number, obj: NodeListOf<TNode>) => boolean, thisArg?: any): TNode | undefined;

    filter(predicate: (value: TNode, index: number, obj: NodeListOf<TNode>) => boolean, thisArg?: any): NodeListOf<TNode>;

    map<U>(callbackfn: (value: TNode, index: number, obj: NodeListOf<TNode>) => U, thisArg?: any): U[];
  }

  interface NodeList {
    find(predicate: (value: Node, index: number, obj: NodeList) => boolean, thisArg?: any): Node | undefined;

    filter(predicate: (value: Node, index: number, obj: NodeList) => boolean, thisArg?: any): NodeList;

    map<U>(callbackfn: (value: Node, index: number, obj: NodeList) => U, thisArg?: any): U[];
  }
}

// @ts-ignore
NodeList.prototype.find = Array.prototype.find;
// @ts-ignore
NodeList.prototype.filter = Array.prototype.filter;
// @ts-ignore
NodeList.prototype.map = Array.prototype.map;

export {}