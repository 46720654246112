import StimulusController from "../lib/stimulus_controller";
import Tooltip from "./components/tooltip";

export default class extends StimulusController<HTMLElement> {
  tooltip!: Tooltip;

  showBound = this.show.bind(this);
  hideBound = this.hide.bind(this);

  connect() {
    this.tooltip = new Tooltip(this.findEl(".tooltip", "No tooltip element found"), this.element);

    for (const el of Array.from(this.findAllEl("[data-tooltip-content]"))) {
      el.addEventListener("mouseenter", this.showBound);
      el.addEventListener("mouseleave", this.hideBound);
    }
  }

  show(e: Event) {
    if (!(e.target instanceof HTMLElement) && !(e.target instanceof SVGElement)) return;

    if (e.target.dataset.tooltipContent) {
      this.tooltip.element.innerHTML = e.target.dataset.tooltipContent;
      this.tooltip
        .showOn(e.target)
        .fitIn(this.element, [0, (document.body.offsetFrom(this.element)?.right ?? 0) / 3 * 2])
        .show();
    }
  }

  hide() {
    this.tooltip.hide();
  }

  disconnect() {
    super.disconnect();

    for (const el of Array.from(this.findAllEl("[data-tooltip-content]"))) {
      el.removeEventListener("mouseenter", this.showBound);
      el.removeEventListener("mouseleave", this.hideBound);
    }
  }
}
