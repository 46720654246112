import {Options, passwordStrength} from 'check-password-strength'
import StimulusController from "../lib/stimulus_controller";


type StrengthOption = {
  id: number,
  value: string,
  minDiversity: number,
  minLength: number
}

export default class extends StimulusController {
  input!: HTMLInputElement;
  errorDiv!: HTMLElement;
  bgColor = "bg-border";
  colors = ["bg-error", "bg-hard-warn", "bg-warn", "bg-success"];
  strengthOptions: Options<string> = [
    {id: 0, value: "Too weak", minDiversity: 0, minLength: 0},
    {id: 1, value: "Weak", minDiversity: 4, minLength: 8},
    {id: 2, value: "Medium", minDiversity: 4, minLength: 12},
    {id: 3, value: "Strong", minDiversity: 4, minLength: 16}
  ];
  validators: Record<string, string> = {
    "[A-Z]": "1 majuscule",
    "[a-z]": "1 minuscule",
    "[0-9]": "1 chiffre",
    "[!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>/?]": "1 caractère spécial",
  };

  ensureDecodeHtml(value: string) : string {
    let txt = document.createElement("textarea");
    txt.innerHTML = value;
    return txt.value
  }

  connect() {
    this.input = this.findEl("input", "No input element found");
    //try to get validators from pattern messages
    const pattern = this.input.dataset.patternMessages;
    if (pattern) this.validators = JSON.parse(pattern);
    //we want to lowercase messages
    for (const key in this.validators) {
      this.validators[key] = this.validators[key].toLowerCase()
        .replace("doit contenir", "")
        .replace("au moins", "");
    }
    const temp_error = this.findEl<HTMLDivElement>("div.error")
    if (!temp_error) {
      this.errorDiv = document.createElement("div");
      this.errorDiv.classList.add("error")
      this.element.insertAdjacentElement("afterbegin", this.errorDiv);
    } else {
      this.errorDiv = temp_error;
    }

    this.errorDiv.innerHTML = this.errorDiv.innerText; // removes elements from hierarchy
    this.errorDiv.innerHTML = `
      <span>${this.errorDiv.innerHTML}</span>
      <div class="flex gap-2xs h-2xs mt-2xs mb-xs">
        <div class="h-full flex-1 rounded-full transition-colors"></div>
        <div class="h-full flex-1 rounded-full transition-colors"></div>
        <div class="h-full flex-1 rounded-full transition-colors"></div>
        <div class="h-full flex-1 rounded-full transition-colors"></div>
      </div>
      <i class="not-italic text-on-background">Votre mot de passe doit contenir au moins:</i>
      <ul class="text-on-background text-s list-disc pl-sm"></ul>
    `;

    let errorDivSpan = this.errorDiv.querySelector<HTMLSpanElement>(":scope > span");

    if (this.input) this.input.addEventListener("input", ({target}) => {
      if (!(target instanceof HTMLInputElement)) return;
      const {value} = target;
      const passStr = passwordStrength(value, this.strengthOptions);
      const passStrId = passStr ? passStr.id : 0;
      this.findAllEl(`:scope > div.error > div > div`).forEach(e => {
        e.classList.remove(...this.colors)
        e.classList.add(this.bgColor)
      });
      this.findAllEl(`:scope > div.error > div > div:nth-child(-n+${passStrId + 1})`).forEach(e => {
        e.classList.remove(this.bgColor)
        e.classList.add(this.colors[passStrId])
      });
      let errors = "";
      let allValid = true;
      for (let [regex, text] of Object.entries(this.validators)) {
        let valid = !!value.match(this.ensureDecodeHtml(regex))
        if (!valid) allValid = false;
        errors += `<li class='${valid ? "line-through text-gray-400" : "text-red-800*"}'>${text}.</li>`;
      }

      if ((allValid || value === "") && errorDivSpan?.innerText) this.errorDiv.classList.add("empty")
      else this.errorDiv.classList.remove("empty")

      this.errorDiv.querySelector(`:scope > ul`)!.innerHTML = errors;
    });

    this.input && this.input.dispatchEvent(new Event("input"))
  }
}
