import {useClickOutside} from "stimulus-use";
import {ClickOutsideComposableController} from "stimulus-use/dist/types/use-click-outside/click-outside-controller";
import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController implements ClickOutsideComposableController {

  get content() { return this.findEl(".dropdown-content", "No content element found"); }

  connect() {
    useClickOutside(this);
  }

  toggle() { this.content.classList.toggle("show"); }

  close() { this.content.classList.remove("show"); }

  open() { this.content.classList.add("show"); }

  clickOutside() { this.close(); }
}
