import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController {
    totalPriceElement!: HTMLElement
    totalQuantityElement!: HTMLElement
    tarifElements!: {
        tarif: HTMLElement,
        quantity: HTMLInputElement,
        price: HTMLElement,
        totalPrice: HTMLElement,
        unitPrice: number,
    }[]
    totalPrice!: number
    totalQuantity!: number

    connect() {
        this.totalQuantityElement = document.getElementById("total-quantity")!
        this.totalPriceElement = document.getElementById("total-price")!

        this.tarifElements = []
        document.querySelectorAll<HTMLElement>(".tarif").forEach((tarif) => {
            let quantity = tarif.querySelector<HTMLInputElement>(".tarif__quantity")!
            quantity.addEventListener("change", () => {
                this.#update()
            })
            let unitPrice = parseFloat(tarif.dataset.price!)

            this.tarifElements.push({
                tarif,
                quantity,
                price: tarif.querySelector(".tarif__unit-price")!,
                totalPrice: tarif.querySelector(".tarif__total-price")!,
                unitPrice,
            })
        })

        this.#update()
    }

    #update() {
        this.totalPrice = 0
        this.totalQuantity = 0

        this.tarifElements.forEach((tarif) => {
            let quantity = parseInt(tarif.quantity.value)
            this.totalPrice += quantity * tarif.unitPrice
            this.totalQuantity += quantity
        })

        this.#render()
    }

    #render() {
        this.totalQuantityElement.innerText = `Qté: ${this.totalQuantity}`
        let e = document.createElement('b')
        e.innerText = this.#formatPrice(this.totalPrice)
        this.totalPriceElement.innerHTML = e.outerHTML

        this.tarifElements.forEach((tarif) => {
            tarif.price.innerText = this.#formatPrice(tarif.unitPrice)
            let e = document.createElement('b')
            e.innerText = this.#formatPrice(tarif.unitPrice * parseInt(tarif.quantity.value))
            tarif.totalPrice.innerHTML = e.outerHTML
        })
    }

    #formatPrice(price: number) {
        return Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(price / 100.0)
    }
}
