import * as Sentry from "@sentry/browser";

// check if local server, checking if port is 3000, host is localhost or hosts ends with .lan
const isLocal = window.location.port === "3000" || window.location.hostname === "localhost" || window.location.hostname.endsWith(".lan");

if (!isLocal) Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  environment: import.meta.env.RAILS_ENV,
  
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: import.meta.env.APP_VERSION,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/billetterie-club.unova.dev/],
  
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});