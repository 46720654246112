// define events maps type
import ReactRailsUJS from "react_ujs";
import {unmountComponentAtNode} from "react-dom";
import React from "react";
import {createRoot, hydrateRoot, Root} from "react-dom/client";
import type {
  TurboBeforeFetchRequestEvent,
  TurboBeforeFetchResponseEvent,
  TurboBeforeFrameRenderEvent,
  TurboBeforeRenderEvent,
  TurboBeforeStreamRenderEvent,
  TurboBeforeVisitEvent,
  TurboClickEvent,
  TurboFetchRequestErrorEvent,
  TurboFrameLoadEvent,
  TurboFrameMissingEvent,
  TurboFrameRenderEvent,
  TurboLoadEvent,
  TurboRenderEvent,
  TurboSubmitEndEvent,
  TurboSubmitStartEvent,
  TurboVisitEvent
} from "../types/turbo-7.3";


type ASDocumentEventMap = {
  "turbo:click": TurboClickEvent,
  "turbo:before-visit": TurboBeforeVisitEvent,
  "turbo:visit": TurboVisitEvent,
  "turbo:submit-start": TurboSubmitStartEvent,
  "turbo:submit-end": TurboSubmitEndEvent,
  "turbo:before-fetch-request": TurboBeforeFetchRequestEvent,
  "turbo:before-fetch-response": TurboBeforeFetchResponseEvent,
  "turbo:before-cache": Event,
  "turbo:before-render": TurboBeforeRenderEvent,
  "turbo:before-stream-render": TurboBeforeStreamRenderEvent,
  "turbo:render": TurboRenderEvent,
  "turbo:load": TurboLoadEvent,
  "turbo:before-frame-render": TurboBeforeFrameRenderEvent,
  "turbo:frame-render": TurboFrameRenderEvent,
  "turbo:frame-load": TurboFrameLoadEvent,
  "turbo:frame-missing": TurboFrameMissingEvent,
  "turbo:fetch-request-error": TurboFetchRequestErrorEvent,
}

// extend document addEventListener to support Turbo events
declare global {
  interface Document {
    addEventListener<K extends keyof ASDocumentEventMap>(type: K, listener: (this: Document, ev: ASDocumentEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
  }
  
  interface Window {
    addEventListener<K extends keyof ASDocumentEventMap>(type: K, listener: (this: Document, ev: ASDocumentEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
  }
  
  interface HTMLElement {
    addEventListener<K extends keyof ASDocumentEventMap>(type: K, listener: (this: Document, ev: ASDocumentEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
  }
}

document.addEventListener("click", (event) => {
  if (!(event.target instanceof HTMLElement) && !(event.target instanceof SVGElement)) return;
  const path = event.target.closest("[data-href]")?.getAttribute("data-href") || null;
  if (path === null) return;
  if (event.target.closest("a[href], form button:not([type]), form [type=submit], [data-action^=click], [data-action*=' click'], input, select, textarea") !== null) return;
  event.preventDefault();
  event.stopPropagation();
  if (Turbo) Turbo.visit(path);
  else window.location.href = path;
})

declare global {
  interface Node {
    __root__?: Root;
    __ssr_content__?: string;
  }
}

if (ReactRailsUJS != undefined) {
  // rewritten ReactRailsUJS.mountComponents to support Turbo and SSR, with root caching
  ReactRailsUJS.mountComponents = function (searchSelector) {
    const ujs = ReactRailsUJS;
    const nodes = ujs.findDOMNodes(searchSelector);
    for (const element of Array.from(nodes)) {
      const node = element as HTMLElement;
      const className2 = node.getAttribute(ujs.CLASS_NAME_ATTR);
      const constructor2 = ujs.getConstructor(className2!);
      const propsJson = node.getAttribute(ujs.PROPS_ATTR);
      const props = propsJson && JSON.parse(propsJson);
      const hydrate = node.getAttribute(ujs.RENDER_ATTR);
      const cacheId = node.getAttribute(ujs.CACHE_ID_ATTR)!;
      const turbolinksPermanent = node.hasAttribute("data-turbo-permanent");
      if (!constructor2) {
        const message = "Cannot find component: '" + className2 + "'";
        if (console && console.log) {
          console.log("%c[react-rails] %c" + message + " for element", "font-weight: bold", "", node);
        }
        throw new Error(message + ". Make sure your component is available to render.");
      } else {
        if (node.__root__) continue;
        let component = this.components[cacheId];
        if (component === void 0) {
          component = React.createElement(constructor2, props);
          if (turbolinksPermanent) {
            this.components[cacheId] = component;
          }
        }
        if (window.debug) console.log("mounting component", component, "on node", node);
        if (hydrate) {
          node.__ssr_content__ = node.innerHTML;
          node.__root__ = hydrateRoot(node, component);
        } else {
          node.__ssr_content__ = node.innerHTML;
          node.__root__ = createRoot(node);
          node.__root__.render(component);
        }
      }
    }
  }
  
  //bound turbo events to ReactRailsUJS to manage component mounting
  document.addEventListener("turbo:render", () => {
    ReactRailsUJS.mountComponents()
  })
  
  document.addEventListener("turbo:frame-render", (event) => {
    if (!(event.target instanceof HTMLElement)) return;
    ReactRailsUJS.mountComponents(event.target.id)
  })
  
  document.addEventListener("turbo:before-frame-render", (event) => {
    if (!(event.target instanceof HTMLElement)) return;
    unmountComponentsInElement(event.target)
  })
  
  document.addEventListener("turbo:before-cache", () => {
    unmountComponentsInElement()
  })
  
  //if caching is disabled in meta tags, before-cache will not fire
  //so we need to unmount components on before-visiting
  document.addEventListener("turbo:before-visit", () => {
    if (document.querySelector("meta[name='turbo-cache-control']")
      ?.getAttribute("content") !== "no-cache") return;
    unmountComponentsInElement()
  })
  
  document.addEventListener("turbo:before-stream-render", (event) => {
    //getting action and target from stream
    const change = {
      action: event.detail.newStream.getAttribute("action")!,
      target: event.detail.newStream.getAttribute("target")!,
    }
    
    //unmounting components in target
    const actionsThatUnmount = ["replace", "update", "remove"];
    const target = document.getElementById(change.target)
    if (actionsThatUnmount.includes(change.action) && target)
      unmountComponentsInElement(target)
    
    //render components in targets
    console.log("rendering stream")
    const _oldRender = event.detail.render;
    event.detail.render = async function (el) {
      await _oldRender(el);
      const target = document.getElementById(change.target)
      //randomize all cache ids to prevent collisions
      target?.querySelectorAll(`[${ReactRailsUJS.CACHE_ID_ATTR}]`).forEach((el) => {
        el.setAttribute(ReactRailsUJS.CACHE_ID_ATTR, el.getAttribute(ReactRailsUJS.CLASS_NAME_ATTR) + "-" + Math.random().toString(9).substring(2, 5))
      });
      if (target) ReactRailsUJS.mountComponents(target)
    }
  })
  
  // will unmount all components in an element, or all components in the document if no element is provided
  function unmountComponentsInElement(element?: HTMLElement) {
    if (element?.__root__) return element.__root__.unmount();
    for (const node of Array.from(ReactRailsUJS.findDOMNodes(element))) {
      if (window.debug) console.log("unmounting components in element", node);
      if (node.__root__) {
        node.__root__.unmount();
        if (node instanceof HTMLElement) node.innerHTML = node.__ssr_content__ || "";
        continue;
      }
      unmountComponentAtNode(node as Element)
    }
    ReactRailsUJS.unmountComponents(element)
  }
}


export {}