import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController {
  mainContainer!: HTMLElement | Window;

  connect() {
    this.mainContainer = document.body.querySelector("main") || window;
    this.update();
    this.mainContainer.addEventListener("scroll", this.update.bind(this))
  }

  update() {
    if (
      (this.mainContainer instanceof Window && this.mainContainer.scrollY > 10) ||
      (this.mainContainer instanceof HTMLElement && this.mainContainer.scrollTop > 10)
    ) this.element.classList.add("active");
    else this.element.classList.remove("active");
  }
}
