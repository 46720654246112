export const dependencies = ["dialog"];

document.addEventListener("DOMContentLoaded", () => {
  window.Turbo.setConfirmMethod((message): Promise<boolean> => {
    let dialog = document.querySelector<HTMLDialogElement>("dialog#turbo-confirm");
    if(!dialog) return (async () => confirm(message))();

    dialog.querySelector(".title")!.textContent = "Confirmer?";
    let messageDiv = dialog.querySelector(".message");
    if(messageDiv) messageDiv.innerHTML = message;

    dialog.showModal();

    return new Promise<boolean>((resolve, reject) => {
      dialog!.addEventListener("close", () => {
        resolve(dialog!.returnValue === "confirm");
      }, { once: true });
    });
  });
}, { once: true });
