// noinspection ES6ConvertVarToLetConst
// TODO: Il faudrait supprimer cette addons est privilégier un import basique
import {get as _get, post as _post} from "../lib/request";

declare global {
  var post: typeof _post;
  var get: typeof _get;
}

globalThis.post = _post;
globalThis.get = _get;
