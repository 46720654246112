import StimulusController from "../lib/stimulus_controller";
import {flash} from "../components/Toast";

export default class extends StimulusController {
    private el: HTMLElement | HTMLInputElement | null | undefined;
    private value: string | null | undefined;

    connect() {
        this.el = this.findEl<HTMLParagraphElement>('[data-clipboard-target="value"]')
        this.value = this.el?.textContent || this.el?.value
    }

    async copy() {
        if (navigator.permissions) {
            try{
                //typescript removed clipboard-write from PermissionName, so we need to cast it
                let result = await navigator.permissions.query({name: 'clipboard-write' as PermissionName})
                if (result.state !== 'granted') {
                    flash('Vous devez autoriser l\'accès au presse-papier.', 'error', 'Erreur');
                    return;
                }
            } catch (e){
                //as clipboard-write is not supported by all browsers, we want to check if a TypeError is thrown, or else, we return
                if(!(e instanceof TypeError)) return console.error(e);
            }
        }

        if (!navigator.clipboard) {
            flash('Impossible de copier la valeur dans le presse-papier.', 'error', 'Erreur');
            return;
        }

        if(typeof this.value === 'string') {
            navigator.clipboard.writeText(this.value).then(
                () => {
                    flash('Copié dans le presse-papier avec succès.');
                },
                () => {
                    flash('Impossible de copier la valeur dans le presse-papier.', 'error', 'Erreur');
                }
            )
        }
    }
}
