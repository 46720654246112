import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController<HTMLTableElement> {
  select!: HTMLSelectElement

  get ids() {
    return this.select.querySelectorAll<HTMLOptionElement>(":scope>option[selected]").map((el) => el.value)
  }

  set ids(ids: string[]) {
    for (const el of Array.from(this.select.querySelectorAll<HTMLOptionElement>(":scope>option"))) {
      el.remove()
    }
    for (const id of ids.filter((el) => el && el.length > 0)) {
      const option = document.createElement("option")
      option.value = id
      option.selected = true
      option.setAttribute("selected", "selected")
      this.select.append(option)
    }
  }

  addId(id: string) {
    if (!this.ids.includes(id)) this.ids = [...this.ids, id]
  }

  addIds(ids: string[]) {
    const current_ids = this.ids
    this.ids = [...this.ids, ...ids.filter((el) => !current_ids.includes(el))]
  }

  removeId(id: string) {
    if (this.ids.includes(id)) this.ids = this.ids.filter((el) => el != id)
  }

  selectAllIds() {
    this.addIds(this.element
      .querySelectorAll<HTMLInputElement>(`tr>td:first-child>input[type=checkbox]:not([data-id="*"])`)
      .map((el) => {
        el.checked = true
        return el.dataset.id || this.throw("No id found")
      })
    )
  }

  unselectAllIds() {
    let visibleCheckboxes = this.element
        .querySelectorAll<HTMLInputElement>(`tr>td:first-child>input[type=checkbox]:not([data-id="*"])`);
    let newIds = this.ids;
    visibleCheckboxes
        .forEach((el) => {
            if(!!el.dataset.id) {
              newIds.splice(newIds.indexOf(el.dataset.id), 1);
            }
        })
    this.ids = newIds;
    visibleCheckboxes
      .forEach((el) => el.checked = false)
  }


  connect() {
    let prev = this.element.previousElementSibling
    const input_name = (this.element.dataset.name || this.throw("No name found")) + "[]"
    if (!(prev instanceof HTMLSelectElement) || !prev.multiple || prev.name !== input_name) {
      this.select = document.createElement("select")
      this.select.multiple = true
      this.element.insertAdjacentElement("beforebegin", this.select)
    } else {
      this.select = prev
    }
    this.select.name = input_name
    this.select.style.display = "none"


    //validate table
    for (const el of Array.from(this.element.querySelectorAll("tr>td:first-child,tr>th:first-child"))) {
      if (el.children.length != 1) this.throw("First cell must contain exactly one element")
      const input = el.children[0]
      if (!(input instanceof HTMLInputElement)) this.throw("First cell must contain exactly one input element")
      if (input.type != "checkbox") this.throw("First cell must contain exactly one checkbox")
    }

    //append event for checkboxes
    this.element.querySelectorAll<HTMLInputElement>("tr>td:first-child>input[type=checkbox],tr>th:first-child>input[type=checkbox]").forEach((el) => {
      el.addEventListener("change", this.onCheckboxChangeBound)
    })

    const selected = this.element.dataset.selected?.split(",")
    const reload = this.element.dataset.reload || false
    if (!reload && selected) {
      this.ids = selected
    }

    this.updateSelected()
  }

  disconnect() {
    super.disconnect();
    this.element.querySelectorAll<HTMLInputElement>("tr>td:first-child>input[type=checkbox],tr>th:first-child>input[type=checkbox]").forEach((el) => {
      el.removeEventListener("change", this.onCheckboxChangeBound)
    })
  }

  onCheckboxChangeBound = this.onCheckboxChange.bind(this)

  onCheckboxChange(event: Event) {
    const input = event.target
    if (!(input instanceof HTMLInputElement)) return
    const id = input.dataset.id || this.throw("No id found")
    if (input.checked) {
      if (input.dataset.id == "*") {
        console.log("select all")
        this.selectAllIds()
      } else {
        this.addId(id)
      }
    } else {
      if (input.dataset.id == "*") {
        this.unselectAllIds()
      } else {
        this.removeId(id)
      }
    }

    this.updateSelected()
  }

  updateSelected() {
    const header_checkbox = this.element.querySelector<HTMLInputElement>(`tr>th:first-child>input[type=checkbox][data-id="*"]`)
    const checkboxes = Array.from(this.element.querySelectorAll<HTMLInputElement>(`tr>td:first-child>input[type=checkbox]:not([data-id="*"])`))
    const selected_ids = this.ids
    for (const el of checkboxes) {
      el.checked = selected_ids.includes(el.dataset.id || this.throw("No id found"))
    }

    if (checkboxes.every((el) => el.checked)) {
      header_checkbox!.checked = true
      header_checkbox!.indeterminate = false
    } else if (checkboxes.some((el) => el.checked)) {
      header_checkbox!.checked = false
      header_checkbox!.indeterminate = true
    } else {
      header_checkbox!.checked = false
      header_checkbox!.indeterminate = false
    }
  }
}
