import StimulusController from "../lib/stimulus_controller";
import {get} from "../lib/request";

export default class extends StimulusController<HTMLFormElement>  {

    declare nextPageValue: number;
    declare observer: MutationObserver;
    declare lastElement: Element | null

    connect() {
        this.nextPageValue = Number(this.element.dataset.nextPageValue || '2');

        this.observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                if (mutation.type == "childList") {
                    mutation.addedNodes.forEach((node) => {
                        if (node instanceof Element && node.classList.contains('page-end')) {
                            this.lastElement = node;
                            setTimeout(() => { this.observeNextPageLink() }, 500);
                        }
                    });
                }
            });
        });

        this.lastElement = this.element.lastElementChild?.lastElementChild || this.element.lastElementChild;
        this.observer.observe(this.element, { subtree: true, childList: true });

        this.observeNextPageLink();
    }

    async observeNextPageLink() {
        if (!this.nextPageValue) return

        await nextIntersection(this.lastElement)
        await this.getNextPage()
    }

    async getNextPage() {
        let query = new URLSearchParams(location.search);
        query.set('page', String(this.nextPageValue));
        freezeScrollOnNextRender();
        const req = await get(`${this.#url()}.turbo_stream?${query}&${this.#search()}`);
        this.nextPageValue = Number(req.headers.get('X-Pagination-Next-Page'));
    }

    #url() {
        return (this.element.dataset.url?.split('?')[0] || location.pathname).replace(/\.[a-z]*$/i, '');
    }

    #search() {
        return (this.element.dataset.url?.split('?')[1])?.replace(/\.[a-z]*$/i, '');
    }

}

const nextIntersection = (targetElement: Element | null) => {
    return new Promise<void>(resolve => {
        new IntersectionObserver(([element]) => {
            if (!element.isIntersecting) {
                return;
            } else {
                resolve();
            }
        }).observe(<Element>targetElement);
    })
}

const freeze = () => {
    // @ts-ignore
    window.Turbo.navigator.currentVisit.scrolled = true;
    document.removeEventListener("turbo:render", freeze);
};

export const freezeScrollOnNextRender = () => {
    document.addEventListener("turbo:render", freeze);
};
