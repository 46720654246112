import dialogPolyfill from 'dialog-polyfill';
import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController<HTMLDialogElement> {
  connect() {
    this.element.tagName == "DIALOG" || this.throw("DialogController must be used on a dialog element", TypeError);
    
    if (window.HTMLDialogElement === undefined) {
      dialogPolyfill.registerDialog(this.element);
      this.element.closeModal = function (value) {
        this.classList.add("hide")
        this.addEventListener('animationend', function _dialogAnimationEndEventFunction() {
          this.classList.remove('hide');
          this.close(value);
          this.returnValue = value;
          let ev = new Event("close");
          this.dispatchEvent(ev);
          this.removeEventListener('animationend', _dialogAnimationEndEventFunction, false);
        }, false);
      }
    }
    
    this.element.addEventListener("click", (e) => {
      if (!(e.target instanceof HTMLElement)) return;
      let closeEl = e.target.closest("dialog [data-close]");
      if (closeEl) this.close(closeEl.getAttribute("data-close") || "close");
    });
    
    if (this.element.hasAttribute("data-auto-open")) this.element.showModal();
    
    this.element.addEventListener('mousedown', this.testClickOutsideBnd);
    this.element.addEventListener('touchstart', this.testClickOutsideBnd);
  }

  testClickOutsideBnd = this.testClickOutside.bind(this);
  testClickOutside(event: MouseEvent | TouchEvent) {
    if(event.target != this.element) return; // close only if the click is triggered directly on the dialog and not on a child
    const {clientX, clientY} = event instanceof MouseEvent ? event : event.touches[0];
    const {top, left, width, height} = this.element.getBoundingClientRect();
    if (!(clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height)) {
      this.clickOutside();
    }
  }

  clickOutside() {
    this.close("outside");
  }
  
  close(reason: string) {
    this.element.closeModal(reason);
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener('mousedown', this.testClickOutsideBnd);
    this.element.removeEventListener('touchstart', this.testClickOutsideBnd);
  }
}
