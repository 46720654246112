import StimulusController from "../lib/stimulus_controller";
import {post} from "../lib/request";

export default class extends StimulusController<HTMLElement> {
  type_select!: HTMLSelectElement;

  connect() {
    this.type_select = this.element.querySelector("select[name*='[type]']") || this.throw("missing type select");

    this.element.querySelectorAll(`[name^="${this.escapeBrackets(this.name_start)}"]`)
      .forEach((el) => {
        el.addEventListener("change", (event) => {
          post(
            this.element.dataset.url || this.throw("missing data-url"),
            {
              body: this.formData,
              query: {
                type: this.type_select.value,
                key: this.element.dataset.key || this.throw("missing data-key"),
                y: this.type_select.name.includes("cibles_y") ? "1" : undefined,
              },
              headers: {
                accept: "text/vnd.turbo-stream.html",
              }
            },
          )
        });
      });
  }

  get name_start() {
    return this.type_select.name.replace("[type]", "");
  }

  // with name = "el[attr][attr2]", start = "el", return "attr[attr2]"
  name_to_root(name: string) {
    return name.replace(this.name_start, "")
      .replace(/\[/, "")
      .replace(/]/, "");
  }

  escapeBrackets(str: string) {
    return str.replaceAll("[", "\\[").replaceAll("]", "\\]");
  }

  get formData() {
    const fd = new FormData(this.type_select.form || this.throw("missing form"));

    // remove type_select from formData because it's provided in query
    fd.delete(this.type_select.name)

    // rename all fields to remove sub hash in rails params, and remove also fields that are not in same scope
    const mov: [string, string | undefined][] = [];
    for (let [name, value] of (fd as any).entries())
      if(name.startsWith(this.name_start)) mov.push([name, undefined], [this.name_to_root(name), value]);
      else mov.push([name, undefined]);
    for (let [name, value] of mov) !value ? fd.delete(name) : fd.append(name, value);

    if ([...(fd as any).entries()].length === 0) return {};

    return fd;
  }
}
