import StimulusController from "../lib/stimulus_controller";

// @William: On garde les targets ici, interface souvent modifiée par turbo
export default class extends StimulusController {
  static targets = ["checkBox", "checkAll"]

  declare readonly checkBoxTargets: HTMLInputElement[]
  declare readonly checkAllTarget: HTMLInputElement

  toggleAll() {
    if (this.checkAllTarget.checked) {
      this.checkBoxTargets.forEach((checkBox) => {
        checkBox.checked = true
      })
    }
  }

  uncheck(checkBox: HTMLInputElement) {
    if (!checkBox.checked && this.checkAllTarget.checked) {
      this.checkAllTarget.checked = false;
    }
  }
}
