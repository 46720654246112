import * as ActiveStorage from "@rails/activestorage"

// start ActiveStorage
ActiveStorage.start()

// define events map type
type ASDocumentEventMap = {
  "direct-uploads:start": CustomEvent & {target: HTMLFormElement},
  "direct-uploads:end": CustomEvent & {target: HTMLFormElement},
  "direct-upload:initialize": CustomEvent<{id: string, file: File}> & {target: HTMLInputElement},
  "direct-upload:start": CustomEvent<{id: string, file: File}> & {target: HTMLInputElement},
  "direct-upload:before-blob-request": CustomEvent<{id: string, file: File, xhr: XMLHttpRequest}> & {target: HTMLInputElement},
  "direct-upload:before-storage-request": CustomEvent<{id: string, file: File, xhr: XMLHttpRequest}> & {target: HTMLInputElement},
  "direct-upload:progress": CustomEvent<{id: string, file: File, progress: number}> & {target: HTMLInputElement},
  "direct-upload:error": CustomEvent<{id: string, file: File, error: any}> & {target: HTMLInputElement},
  "direct-upload:end": CustomEvent<{id: string, file: File}> & {target: HTMLInputElement},
}

// extend document addEventListener to support ActiveStorage events
declare global{
  interface Document {
    addEventListener<K extends keyof ASDocumentEventMap>(type: K, listener: (this: Document, ev: ASDocumentEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
  }
}

document.addEventListener("direct-upload:initialize", ({target, detail: {id}})=> {
  let container = document.querySelector(`label[for="${target.id}"] .preview-container`);
  let progressBackdrop;
  if(!container){
    progressBackdrop = document.createElement("div");
    target.insertAdjacentElement("afterend", progressBackdrop);
  } else {
    progressBackdrop = document.createElement("div");
    progressBackdrop.classList.add(..."bg-softblack bg-opacity-70 absolute inset-0 flex flex-col items-center justify-center text-center px-4".split(" "));
    container.insertAdjacentElement("beforeend", progressBackdrop);
  }
  progressBackdrop.insertAdjacentHTML("afterbegin", `<div class="progress pending w-full" style="--value: 0%" data-for="${target.id || id}"></div>`);
  target.form?.querySelectorAll<HTMLInputElement>("input[type=submit], [data-form-type=action]").forEach(e=>{
    if(e.disabled !== undefined) e.disabled = true;
    e.classList.add("disabled")
    if(e.textContent !== undefined) e.textContent = "<%= I18n.t('uploading_files') %>";
    if(e.value !== undefined) e.value = "<%= I18n.t('uploading_files') %>";
  });
})

document.addEventListener("direct-upload:start", ({target, detail: {id}}) => {
  const progress = document.querySelector<HTMLDivElement>(`div.progress[data-for="${target.id ||id}"]`);
  progress?.classList.remove("pending");
})

document.addEventListener("direct-upload:progress", ({target, detail: {id, progress}}) => {
  const progressEl = document.querySelector<HTMLDivElement>(`div.progress[data-for="${target.id ||id}"]`);
  progressEl?.style.setProperty(`--value:`, `${progress}%`);
})

document.addEventListener("direct-upload:error", ({target, detail: {id, error}}) => {
  const progress = document.querySelector<HTMLDivElement>(`div.progress[data-for="${target.id ||id}"]`);
  progress?.classList.add("error");
  progress?.insertAdjacentHTML("afterend", `<div class="text-red-500">${error}</div>`)
})

document.addEventListener("direct-upload:end", ({target, detail: {id}}) => {
  const progress = document.querySelector(`div.progress[for="${target.id ||id}"]`);
  progress?.classList.add("success");
})