declare global {
  interface HTMLDialogElement {
    closeModal(value: any): void
  }
}

if (window.HTMLDialogElement == undefined) {
} else {
  HTMLDialogElement.prototype.closeModal = function (value: any) {
    this.classList.add("hide")
    this.addEventListener('animationend', function _dialogAnimationEndEventFunction() {
      this.classList.remove('hide');
      this.close(value);
      this.returnValue = value;
      let ev = new Event("close");
      this.dispatchEvent(ev);
      this.removeEventListener('animationend', _dialogAnimationEndEventFunction, false);
    }, false);
  }
}


export {}
