import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController<HTMLFormElement>  {

    connect() {
        document.addEventListener('turbo:submit-end', (event) => {
            const form = event.detail.formSubmission
            if(
                form.result?.success &&
                form.formElement?.action &&
                form.formElement.action.indexOf('export_billets') > -1
            ) {
                document.getElementById('downloads')?.click();
            }
        })
    }

}