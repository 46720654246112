import StimulusController from "../lib/stimulus_controller";

// @William: On garde les targets ici, interface souvent modifiée par turbo
export default class extends StimulusController<HTMLFormElement>  {

    connect() {
        if (!(this.element instanceof HTMLFormElement)) this.throw("Form element not found", Error);
    }

    download(event: Event) {
        event.preventDefault();
        let selectedValues:NodeListOf<HTMLInputElement>
        selectedValues = document.querySelectorAll('[data-check-group-target="checkBox"]:checked');
        if(selectedValues.length<=0){return};
        let values:Array<Number> = [];
        selectedValues.forEach( (checkedBox) => {
            if(!isNaN(Number(checkedBox.value))) {
                values.push(Number(checkedBox.value))
            }
        })
        if(values.length<=0){return};
        let input:HTMLInputElement = this.findEl('[name="document_ids"]', "Not found");
        input.value = JSON.stringify(values);
        this.element.requestSubmit();
    }
}
