import StimulusController from "../lib/stimulus_controller";
import {FetchRequest} from "../lib/request";
import {useClickOutside} from "stimulus-use";

function replaceQueryParam(param: string, newval: any, search: string) {
  let regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
  let query = search.replace(regex, "$1").replace(/&$/, '');

  return (query.length > 2 ? query + "&" : "?") + (newval ? param + "=" + newval : '');
}

export default class extends StimulusController<HTMLFormElement> {
  timeout?: NodeJS.Timeout;
  updateBound = this.update.bind(this);
  preventAndUpdateBound = this.preventAndUpdate.bind(this);
  input = this.findEl<HTMLInputElement>("input[type=search][name=query]", "Cannot find input");

  connect() {
    if (!(this.element instanceof HTMLFormElement)) this.throw("Form element not found", Error);

    this.input.addEventListener("focus", this.updateBound)
    this.input.addEventListener("input", this.updateBound)
    this.element.addEventListener("submit", this.preventAndUpdateBound);

    useClickOutside(this)
  }

  preventAndUpdate(e: Event) {
    e.preventDefault();
    this.update();
  }

  clickOutside() {
    this.input.closest(".dropdown")?.querySelector(".dropdown-content")?.classList.remove("show");
  }

  update() {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const request = this.input.value.trim() ? new FetchRequest(
        this.element.method,
        this.element.action,
        {
          body: JSON.stringify({query: this.input.value}),
          headers: {Accept: "application/json", "Content-Type": "application/json"}
        }
      ) : {perform: async () => ({json: (async () => [])()})};
      request.perform().then(
        async response => {
          const data: { value: string, label: string }[] = await response.json;

          const ddContent = this.input.closest(".dropdown")?.querySelector(".dropdown-content")
          if (!(ddContent instanceof HTMLElement)) return;

          ddContent.innerHTML = "";

          data.unshift({value: "", label: "Aucun"})

          let path = this.element.dataset.sourcePath || window.location.pathname

          for (const {value, label} of data) {
            const option = document.createElement("a");
            option.classList.add("btn-background", "w-full", "justify-start");
            let search = replaceQueryParam(this.element.dataset.queryKey || "query", value, window.location.search)
            option.setAttribute("href", `${path}${search}`);
            option.textContent = label;
            ddContent.appendChild(option);
          }
          ddContent.classList.add("show");
        }
      )
    }, 300)
  }

  disconnect() {
    super.disconnect();
    this.input.removeEventListener("input", this.updateBound);
    this.element.removeEventListener("submit", this.preventAndUpdateBound);
  }
}
