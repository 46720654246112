import StimulusController from "../lib/stimulus_controller";
import type {Appearance, Stripe, StripeElements} from "@stripe/stripe-js";
import { flash } from "../components/Toast";

export default class extends StimulusController<HTMLElement> {
  stripe !: Stripe
  elements !: StripeElements
  formContainer = this.element.querySelector<HTMLElement>('#stripe-form') || this.throw('Missing form container')
  messageContainer = this.element.querySelector<HTMLElement>('#stripe-message') || this.throw('Missing message container')
  submitButton = this.element.querySelector<HTMLButtonElement>('#stripe-submit') || this.throw('Missing submit button')
  loaded = false

  async connect() {
    this.stripe = await ((await import('@stripe/stripe-js')).loadStripe)(
      this.element.dataset.key || this.throw('Missing data-key value')
    ) || this.throw('Failed to load stripe')

    const appearance: Appearance = {
      theme: 'stripe',
    }

    this.elements = this.stripe.elements({appearance, clientSecret: this.element.dataset.clientSecret})

    const paymentElement = this.elements.create('payment', {
      layout: "tabs",
    });

    paymentElement.mount(this.formContainer);

    this.submitButton.addEventListener('click', this.handleSubmit.bind(this))

    this.formContainer.querySelector(".__PrivateStripeElement iframe")?.addEventListener('load', () => {
      this.loaded = true
    })

    this.setLoading(false)
  }

  async handleSubmit(e: MouseEvent) {
    if (
      this.formContainer.querySelector(".__PrivateStripeElement") === null ||
      this.formContainer.querySelector(".__PrivateStripeElement iframe") === null ||
      !this.loaded
    ) return;
    e.preventDefault();
    this.setLoading(true);

    const {error} = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: this.element.dataset.returnUrl || this.throw('Missing data-return-url value'),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      if (error.message) this.showMessage(error.message);
    } else if (error.type === "invalid_request_error") {
      this.showMessage("Le délai pour le paiement est dépassé.");
    } else {
      this.showMessage("Une erreur inattendue s'est produite.");
    }

    this.setLoading(false);
  }

  // ------- UI helpers -------
  showMessage(messageText: string) {
    flash(messageText, "error", "Erreur paiement")
  }

  // Show a spinner on payment submission
  setLoading(isLoading: boolean) {
    this.submitButton.disabled = isLoading;
  }
}