import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController {

  cache: {
    container?: HTMLElement | null,
    leftThumb?: HTMLElement | null,
    leftThumbIndicator?: HTMLElement | null,
    rightThumb?: HTMLElement | null,
    rightThumbIndicator?: HTMLElement | null,
    minInput?: HTMLInputElement | null,
    maxInput?: HTMLInputElement | null,
    sliderIndicator?: HTMLElement | null,
  } = {}

  get container() {
    if (!this.cache.container) this.cache.container = this.findEl(":scope>div.slider-content");
    return this.cache.container!
  }

  get leftThumb() {
    if (!this.cache.leftThumb) this.cache.leftThumb = this.container.querySelector(":scope>div.slider-touch-left");
    return this.cache.leftThumb!
  }

  get leftThumbIndicator() {
    if (!this.cache.leftThumbIndicator) this.cache.leftThumbIndicator = this.leftThumb.querySelector(":scope>div.value-indicator");
    return this.cache.leftThumbIndicator!
  }

  get rightThumb() {
    if (!this.cache.rightThumb) this.cache.rightThumb = this.container.querySelector(":scope>div.slider-touch-right");
    return this.cache.rightThumb!
  }

  get rightThumbIndicator() {
    if (!this.cache.rightThumbIndicator) this.cache.rightThumbIndicator = this.rightThumb.querySelector(":scope>div.value-indicator");
    return this.cache.rightThumbIndicator!
  }

  get minInput() {
    if (!this.cache.minInput) this.cache.minInput = this.findEl<HTMLInputElement>("input.range-min", "No min input element found");
    return this.cache.minInput!
  }

  get maxInput() {
    if (!this.cache.maxInput) this.cache.maxInput = this.findEl<HTMLInputElement>("input.range-max", "No max input element found");
    return this.cache.maxInput!
  }

  get sliderIndicator() {
    if (!this.cache.sliderIndicator) this.cache.sliderIndicator = this.container.querySelector(":scope>div.slide-indicator");
    return this.cache.sliderIndicator!
  }

  minValue = 0.0;
  maxValue = 0.0;
  step = 0.0;
  stepNumber = 0;
  valueIndicatorSuffix = "";

  get slideStart() {
    return this.leftThumb.offsetWidth / 2
  }

  get slideEnd() {
    return this.container.offsetWidth - this.rightThumb.offsetWidth / 2
  }

  set leftThumbPosition(pos) {
    this.leftThumb.style.left = (pos - (this.leftThumb.offsetWidth / 2)) + "px";
    this.sliderIndicatorPos = {start: pos}
  }

  set rightThumbPosition(pos) {
    this.rightThumb.style.left = pos + "px"
    this.sliderIndicatorPos = {end: pos}
  }

  set sliderIndicatorPos({start, end}: { start?: number, end?: number }) {
    start = start ?? this.leftThumbPosition;
    start -= this.leftThumb.offsetWidth / 4
    end = end ?? this.rightThumbPosition;
    end += this.rightThumb.offsetWidth / 4 + this.leftThumb.offsetWidth / 4
    this.sliderIndicator.style.left = start + "px";
    this.sliderIndicator.style.width = end - start + "px";
  }

  get leftThumbPosition() {
    return parseFloat(this.leftThumb.style.left.split("px")[0]) + (this.leftThumb.offsetWidth / 2)
  }

  get rightThumbPosition() {
    return parseFloat(this.rightThumb.style.left.split("px")[0])
  }

  set leftThumbValue(v: string | number) {
    this.leftThumbIndicator.textContent = v + this.valueIndicatorSuffix;
    this.minInput.value = v + "";
  }

  set rightThumbValue(v: string | number) {
    this.rightThumbIndicator.textContent = v + this.valueIndicatorSuffix;
    this.maxInput.value = v + ""
  }

  stepWidth = 0;

  moveThumbBound = this.moveThumb.bind(this)
  stopMovingBound = this.stopMoving.bind(this)
  isLeftThumbMoving = false;
  moveOffset = 0;
  thumbOffset = 0;


  connect() {
    let tempMinValue = this.element.getAttribute("data-min-value");
    this.minValue = tempMinValue ? parseFloat(tempMinValue) : 0.0
    let tempMaxValue = this.element.getAttribute("data-max-value");
    this.maxValue = tempMaxValue ? parseFloat(tempMaxValue) : 100.0
    let tempStep = this.element.getAttribute("data-step");
    this.step = tempStep ? parseFloat(tempStep) : 1.0
    let tempValueIndicatorSuffix = this.element.getAttribute("data-value-indicator-suffix");
    this.valueIndicatorSuffix = tempValueIndicatorSuffix ? tempValueIndicatorSuffix : ""

    this.stepNumber = Math.floor((this.maxValue - this.minValue) / this.step)

    //for hot reloading, remove the old slider
    this.container?.remove()
    this.cache.container = null

    this.minInput.insertAdjacentHTML("afterend", `
          <div class="slider-content">
            <div class="slider-touch-left" data-action="mousedown->range#moveLeftThumb touchstart->range#moveLeftThumb"><div class="value-indicator">0${this.valueIndicatorSuffix}</div></div>
            <div class="slide-indicator"></div>
            <div class="slider-touch-right" data-action="mousedown->range#moveRightThumb touchstart->range#moveRightThumb"><div class="value-indicator">0${this.valueIndicatorSuffix}</div></div>
          </div>
        `)
    this.minInput.addEventListener("input", this.minInputChange.bind(this))
    this.maxInput.addEventListener("input", this.maxInputChange.bind(this))

    this.leftThumbPosition = this.slideStart
    this.rightThumbPosition = this.slideEnd
    this.stepWidth = (this.slideEnd - this.slideStart) / this.stepNumber
    if (this.minInput.value) this.minInput.dispatchEvent(new Event("input"))
    if (this.maxInput.value) this.maxInput.dispatchEvent(new Event("input"))
  }

  minInputChange() {
    try {
      const val = Math.min(this.maxInput.value ? parseFloat(this.maxInput.value) : this.maxValue, Math.max(0, parseFloat(this.minInput.value)))
      this.leftThumbPosition = val * this.stepWidth + this.slideStart;
      this.minValue = val
    } catch (_) {
    }
  }

  maxInputChange() {
    try {
      const val = Math.max(this.minInput.value ? parseFloat(this.minInput.value) : 0, Math.min(this.maxValue, parseFloat(this.maxInput.value)))
      this.rightThumbPosition = val * this.stepWidth + this.slideStart;
      this.maxValue = val
    } catch (_) {
    }
  }

  moveRightThumb(event: TouchEvent | MouseEvent) {
    event.preventDefault();
    let eventTouch!: { pageX: number };
    if (event instanceof MouseEvent) eventTouch = event;
    if (event instanceof TouchEvent && event.touches) eventTouch = event.touches[0];
    this.isLeftThumbMoving = false;
    this.moveOffset = eventTouch.pageX;
    this.thumbOffset = this.rightThumbPosition;
    document.addEventListener("mousemove", this.moveThumbBound)
    document.addEventListener("touchmove", this.moveThumbBound)
    document.addEventListener("mouseup", this.stopMovingBound)
    document.addEventListener("touchend", this.stopMovingBound)
    this.rightThumb.classList.add("active");
  }

  moveLeftThumb(event: TouchEvent | MouseEvent) {
    event.preventDefault();
    let eventTouch!: { pageX: number };
    if (event instanceof MouseEvent) eventTouch = event;
    if (event instanceof TouchEvent && event.touches) eventTouch = event.touches[0];
    this.isLeftThumbMoving = true;
    this.moveOffset = eventTouch.pageX;
    this.thumbOffset = this.leftThumbPosition;
    document.addEventListener("mousemove", this.moveThumbBound)
    document.addEventListener("touchmove", this.moveThumbBound)
    document.addEventListener("mouseup", this.stopMovingBound)
    document.addEventListener("touchend", this.stopMovingBound)
    this.leftThumb.classList.add("active");
  }

  moveThumb(event: TouchEvent | MouseEvent) {
    let eventTouch!: { pageX: number };
    if (event instanceof MouseEvent) eventTouch = event;
    if (event instanceof TouchEvent && event.touches) eventTouch = event.touches[0];
    let movedX = eventTouch.pageX - this.moveOffset;
    if (this.isLeftThumbMoving) {
      let pos = Math.max(Math.min(movedX + this.thumbOffset, this.rightThumbPosition, this.slideEnd), this.slideStart)
      pos = Math.round((pos - this.slideStart) / this.stepWidth)
      this.leftThumbValue = pos * this.step;
      this.leftThumbPosition = pos * this.stepWidth + this.slideStart;
    } else {
      let pos = Math.max(Math.min(movedX + this.thumbOffset, this.slideEnd), this.slideStart, this.leftThumbPosition)
      pos = Math.round((pos - this.slideStart) / this.stepWidth)
      this.rightThumbValue = pos * this.step;
      this.rightThumbPosition = pos * this.stepWidth + this.slideStart;
    }
  }

  stopMoving(event: TouchEvent | MouseEvent) {
    document.removeEventListener("mousemove", this.moveThumbBound)
    document.removeEventListener("touchmove", this.moveThumbBound)
    document.removeEventListener("mouseup", this.stopMovingBound)
    document.removeEventListener("touchend", this.stopMovingBound)
    this.rightThumb.classList.remove("active");
    this.leftThumb.classList.remove("active");
  }
}
