import StimulusController from "../lib/stimulus_controller";

type SidebarData = {
  collapsed: boolean,
  collapsedGroupIndexes: number[]
}

export default class extends StimulusController {
  private sideData!: HTMLDivElement;

  connect() {
    this.sideData = this.element.previousElementSibling as HTMLDivElement;
    if (!this.sideData || this.sideData.id != "sidebar-dashboard-state-data" || !this.sideData.classList.contains("side-data")) {
      this.sideData = document.createElement("div");
      this.sideData.id = "sidebar-dashboard-state-data"
      this.sideData.classList.add("side-data");
      this.sideData.setAttribute("data-turbo-permanent", "");
      this.element.insertAdjacentElement("beforebegin", this.sideData);
    }
    this.element.addEventListener("click", ({target}) => {
      if (!(target instanceof HTMLElement)) return;
      let toggleBtn = target.closest("nav.sidebar > header > button")
      if (toggleBtn) return this.toggle();
      let toggleGroupBtn = target.closest<HTMLElement>("nav.sidebar:not(.collapsed) > div.link-group > button")
      if (toggleGroupBtn) return this.toggleGroup(toggleGroupBtn);
    })
    this.load()
    if (this.element.hasAttribute("data-collapse")) {
      this.sideData.classList.add("collapse")
      this.element.removeAttribute("data-collapse")
    }
  }

  disconnect() {
    super.disconnect();
    if (this.element.hasAttribute("data-collapse")) this.sideData.classList.remove("collapse")
  }

  toggle() {
    this.sideData.classList.toggle("collapse")
    if (this.sideData.classList.contains("collapse")) {
      this.findAllEl("div.link-group > button.active + div").forEach(el => {
        el.slideDown().then()
      });
    } else {
      this.findAllEl("div.link-group > button.active + div").forEach(el => {
        el.slideUp().then()
      });
    }
    this.persist()
  }

  toggleGroup(btnEl: HTMLElement) {
    if (btnEl.nextElementSibling) btnEl.nextElementSibling.slideToggle(200).then(({collapsed}) => {
      if (collapsed) btnEl.classList.add("active");
      else btnEl.classList.remove("active");
      this.persist()
    })
  }

  persist() {
    const collapsed = this.sideData.classList.contains("collapse");
    const collapsedGroupIndexes = Array.from(this.findAllEl("div.link-group > button"))
      .map((el, i) => el.classList.contains("active") ? i : null)
      .filter<number>((el): el is number => el !== null);

    localStorage.setItem("sidebar-data", JSON.stringify({
      collapsed: collapsed,
      collapsedGroupIndexes: collapsedGroupIndexes
    } as SidebarData));
  }

  load() {
    const dataStr = localStorage.getItem("sidebar-data");
    if (!dataStr) return;
    try {
      const data: SidebarData = JSON.parse(dataStr);
      if (data.collapsed && !this.sideData.classList.contains("collapse"))
        this.toggle();

      const groupButtons = this.findAllEl<HTMLButtonElement>("div.link-group > button");
      data.collapsedGroupIndexes.forEach((i) => {
        if (!data.collapsed && groupButtons[i]) this.toggleGroup(groupButtons[i])
        else if (groupButtons[i]) groupButtons[i].classList.add("active")
      })
    } catch (_) {
    }
  }
}
