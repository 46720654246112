declare global {
  interface String {
    get toInt(): number;

    get toFloat(): number;

    get toBool(): boolean;

    get capitalize(): string;

    get titleize(): string;

    get humanize(): string;

    get underscore(): string;

    get dasherize(): string;

    get camelize(): string;

    get toLower(): string;

    get toUpper(): string;
  }
}

Object.defineProperties(String.prototype, {
  toInt: {
    get: function () {
      return parseInt(this);
    }
  },
  toFloat: {
    get: function () {
      return parseFloat(this);
    }
  },
  toBool: {
    get: function () {
      return ["true", "1", "yes", "on"].includes(this.toLowerCase());
    }
  },
  capitalize: {
    get: function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    }
  },
  titleize: {
    get: function () {
      return this.split("_").map((e: string) => e.capitalize).join(" ");
    }
  },
  humanize: {
    get: function () {
      return this.titleize;
    }
  },
  underscore: {
    get: function () {
      return this.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
    }
  },
  dasherize: {
    get: function () {
      return this.underscore.replace(/_/g, "-");
    }
  },
  camelize: {
    get: function () {
      return this.replace(/(^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
        return index === 0 ? word.toLowerCase() : word.capitalize;
      }).replace(/\s+/g, "");
    }
  },
  toLower: {
    get: function () {
      return this.toLowerCase();
    }
  },
  toUpper: {
    get: function () {
      return this.toUpperCase();
    }
  }
})


export {}