import {flash} from "../components/Toast";

const bad_requests: Record<number, string> = {
  400: "La requête est incorrecte.",
  401: "Vous n'êtes pas autorisé à accéder à cette page.",
  402: "Vous n'êtes pas autorisé à accéder à cette page.",
  403: "Vous n'êtes pas autorisé à accéder à cette page.",
  404: "La page que vous cherchez n'existe pas.",
  405: "Cette page n'est pas accessible via la méthode utilisée.",
  406: "Requête non acceptée.",
  407: "Vous n'êtes pas autorisé à accéder à cette page.",
  408: "La requête a expiré.",
  409: "La requête a un conflit.",
  410: "La page que vous cherchez n'existe plus.",
  411: "Longueur manquante dans la requête.",
  412: "La requête a été préconditionnée.",
  413: "La requête est trop volumineuse.",
  414: "L'URI est trop longue.",
  415: "Le type de média n'est pas supporté.",
  416: "La plage demandée est invalide.",
  417: "La requête n'a pas pu être traitée.",
  418: "Je suis une théière.",
  421: "La requête a mal été redirigée.",
  422: "Il y a des erreurs dans le formulaire.",
  423: "La ressource est verrouillée.",
  424: "La requête a échoué.",
  425: "La requête a été trop rapide.",
  426: "Client obsolète.",
  428: "La requête doit être conditionnelle.",
  429: "Vous avez envoyé trop de requêtes. Veuillez réessayer plus tard.",
  431: "L'entête de la requête est trop volumineuse.",
  451: "Cette page n'est pas disponible dans votre pays.",

  500: "Une erreur est survenue. Veuillez réessayer plus tard.",
  501: "Cette page n'est pas encore disponible.",
  502: "Mauvaise passerelle.",
  503: "Le serveur est indisponible. Veuillez réessayer plus tard.",
  504: "Le serveur a expiré. Veuillez réessayer plus tard.",
  505: "La version HTTP n'est pas supportée.",
  506: "La négociation de contenu a échoué.",
  507: "L'espace de stockage est insuffisant.",
  508: "Une boucle est détectée.",
  510: "La requête n'est pas étendue.",
  511: "Authentification réseau requise.",
}

document.addEventListener("turbo:before-fetch-response", (event) => {
  if (bad_requests[event.detail.fetchResponse.statusCode]) {
    flash(bad_requests[event.detail.fetchResponse.statusCode], "error", "Erreur " + event.detail.fetchResponse.statusCode)
  }
});