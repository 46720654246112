import StimulusController from "../lib/stimulus_controller";

export default class extends StimulusController<HTMLElement> {
  footer = document.querySelector("footer");
  updateBound = this.update.bind(this);

  connect() {
    window.addEventListener("scroll", this.updateBound);
  }

  disconnect() {
    super.disconnect();
    window.removeEventListener("scroll", this.updateBound);
  }

  update(e: Event) {
    if (!this.element || !this.footer) return;
    let elementBottomOffsetFromScreenTop = this.element.getBoundingClientRect().bottom;
    const footerTopOffsetFromScreenTop = this.footer.getBoundingClientRect().top;

    if (this.element.classList.contains("translate-y-full"))
      elementBottomOffsetFromScreenTop -= (new WebKitCSSMatrix(getComputedStyle(this.element).transform)).m42;

    if (
      elementBottomOffsetFromScreenTop > footerTopOffsetFromScreenTop &&
      document.documentElement.scrollTop > 0
    ) {
      this.element.classList.add("translate-y-full");
    } else {
      this.element.classList.remove("translate-y-full");
    }
  }
}
