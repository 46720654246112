import StimulusController from "../lib/stimulus_controller";

type TimerType = "text" | "progress" | "both"

export default class extends StimulusController<HTMLElement> {
  time!: number;
  maxTime!: number;
  interval!: NodeJS.Timeout;
  textElement!: HTMLElement;
  progressElement!: HTMLProgressElement;
  canRefresh!: boolean;
  fresh: boolean = true;

  connect() {
    this.time = parseInt(this.element.dataset.time || this.throw("Missing time"));
    this.maxTime = parseInt(this.element.dataset.maxTime || this.time.toString());

    this.canRefresh = this.element.hasAttribute("data-can-refresh")

    this.textElement = this.findEl("span") || this.element.appendChild(document.createElement("span"));

    this.progressElement = this.findEl("progress") || this.element.appendChild(document.createElement("progress"));

    if (this.element.dataset.type == "text" || !this.element.dataset.type) {
      this.progressElement.style.display = "none";
    }

    if (this.element.dataset.lastRefresh) {
      let lastRefresh = parseInt(this.element.dataset.lastRefresh);
      let now = Date.now();
      this.time -= Math.floor((now - lastRefresh) / 1000);
      if (this.time <= 0) {
        this.time = 0;
      }
    }

    this.interval = setInterval(this.updateTime.bind(this), 1000);
    this.updateTime()
  }

  updateTime() {
    this.time--;
    if (this.time <= 0 && !this.fresh) {
      clearInterval(this.interval);
      if (this.canRefresh) {
        setTimeout(() => {
          window.Turbo.clearCache();
          window.Turbo.visit(window.location.href)
        }, 3000);
      }
      return;
    }
    this.element.dataset.time = this.time.toString();
    if (this.element.dataset.type == "text" || this.element.dataset.type == "both" || !this.element.dataset.type) {
      this.textElement.textContent = this.time <= 0 ? "Expiré!" : `${Math.floor(this.time / 60)} min ${this.time % 60} sec`;
    }
    if (this.element.dataset.type == "progress" || this.element.dataset.type == "both") {
      this.progressElement.value = this.time;
      this.progressElement.max = this.maxTime;
    }
    this.fresh = false;

    this.element.dataset.lastRefresh = Date.now().toString();
  }

  disconnect() {
    super.disconnect();
    clearInterval(this.interval);
  }
}
