import StimulusController from "../lib/stimulus_controller";
import {FetchRequest} from "../lib/request";

export default class extends StimulusController<HTMLFormElement> {
  timeout?: NodeJS.Timeout;
  updateBound = this.update.bind(this);
  preventAndUpdateBound = this.preventAndUpdate.bind(this);
  resetBound = this.reset.bind(this);
  resetPage = false

  connect() {
    if (!(this.element instanceof HTMLFormElement)) this.throw("Form element not found", Error);
    //find all inputs, select, textarea
    this.findAllEl("input, textarea").forEach(el => {
      el.addEventListener("input", this.updateBound)
    })

    this.findAllEl("select").forEach(el => {
      el.addEventListener("change", this.updateBound)
    })

    this.findAllEl("button[type=reset]").forEach(el => {
      el.addEventListener("click", this.resetBound)
    })

    this.element.addEventListener("submit", this.preventAndUpdateBound);
  }

  reset(e: Event) {
    e.preventDefault();

    this.findAllEl("input, textarea").forEach(el => {
      if (!(el instanceof HTMLInputElement) && !(el instanceof HTMLTextAreaElement)) return;
      if (el.type == "reset" || el.type == "submit" || el.type == "button") return;
      if (["checkbox", "radio"].includes(el.type) && "checked" in el) el.checked = false;
      else el.value = "";
    })

    this.findAllEl("select").forEach(el => {
      el.selectedIndex = 0;
    })

    this.update()
  }

  preventAndUpdate(e: Event) {
    e.preventDefault();
    this.update();
  }

  update() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const formData = new FormData(this.element);

      // find all multiple selects, and append all selected options individually, because the way rails handles array params:
      // for param[] = [1,2,3] rails wants param[]=1&param[]=2&param[]=3
      const selects = this.findAllEl<HTMLSelectElement>("select[multiple]");
      selects.forEach(s => {
        const options = s.querySelectorAll<HTMLOptionElement>("option[selected]");
        formData.delete(s.name)
        for (const o of Array.from(options)) {
          formData.append(s.name, o.value);
        }
      })

      // @ts-ignore
      const params = new URLSearchParams([...formData.entries()].map(([key, value]) => [key, value.toString()]));
      const oldParams = new URLSearchParams(location.search);

      params.delete("authenticity_token");
      if (this.resetPage) oldParams.set("page", "1");

      const names = Array.from(this.findAllEl<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>("input, textarea, select"))
        .map(el => el.name)
      for (const name of names) if (oldParams.has(name)) oldParams.delete(name);
      // if we forgot input type from the form found by names, we ensure that the value is deleted from the oldParams
      for (const key of params.keys()) if (oldParams.has(key)) oldParams.delete(key);

      const request = new FetchRequest(
        this.element.method,
        this.element.action,
        {
          body: formData,
          headers: {Accept: "text/vnd.turbo-stream.html"}
        }
      )

      const parsedParams = new URLSearchParams([
        ...oldParams.entries(),
        ...[...params.entries()].filter(([key, value]) => value)
      ])

      window.history.replaceState(null, "", location.pathname + "?" + parsedParams.toString());

      request.perform().then();
    }, 300)
  }

  disconnect() {
    super.disconnect();
    this.findAllEl("input, textarea").forEach(el => {
      el.removeEventListener("input", this.updateBound)
    })

    this.findAllEl("select").forEach(el => {
      el.removeEventListener("change", this.updateBound)
    })

    this.findAllEl("button[type=reset]").forEach(el => {
      el.removeEventListener("click", this.resetBound)
    })

    this.element.removeEventListener("submit", this.preventAndUpdateBound);
  }
}
