import StimulusController from "../lib/stimulus_controller";
import Tooltip from "./components/tooltip";

export default class extends StimulusController<HTMLElement> {
  tooltip!: Tooltip;

  showBound = this.show.bind(this);
  hideBound = this.hide.bind(this);

  connect() {
    this.tooltip = new Tooltip(this.findEl(".tooltip", "No tooltip element found"));

    // this is a hack to make sure the tooltip is positioned correctly
    this.show()
    this.hide()

    this.element.addEventListener("mouseenter", this.showBound);
    this.element.addEventListener("mouseleave", this.hideBound);
  }

  show() {
    this.tooltip
      .showOn(this.tooltip.element.parentElement!)
      .fitIn(document.body, [0, (document.body.offsetFrom(this.element)?.right || 0) / 3 * 2])
      .show();
  }

  hide() {
    this.tooltip.hide();
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener("mouseenter", this.showBound);
    this.element.removeEventListener("mouseleave", this.hideBound);
  }
}
